import "./styles.scss";

import React from "react";
import { useField } from "formik";
import classnames from "classnames";

const Checkbox = ({ label, name, type = "checkbox", ...rest }) => {
  const [field, meta] = useField({ name, type });

  const is_error = meta.touched && !!meta.error;

  return (
    <div
      className={classnames("checkbox", {
        "checkbox--error": !!is_error,
      })}
    >
      <label>
        {label}
        <input type={type} id={name} {...field} {...rest} />
        <span className="checkmark" />
      </label>
    </div>
  );
};

export default Checkbox;
