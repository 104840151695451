import "./styles.scss";

import React, { useEffect } from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import nav from "libs/nav";

import CrossIcon from "icons/cross.svg";

const mainClass = "layout-mobile-menu";

const Header = ({ is_active, onExit }) => {
  useEffect(() => {
    if (!!!is_active) return;

    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [is_active]);

  return (
    <header
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!is_active,
      })}
    >
      <div className="container">
        <div className={`${mainClass}__menu`}>
          <CrossIcon onClick={onExit} />
          <nav>
            {nav?.map(({ label, href }, index) => (
              <Link
                key={index}
                to={href}
                activeClassName="current"
                onClick={onExit}
              >
                {label}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
