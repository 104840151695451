import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

const mainClass = "custom-button";

const Button = ({
  children,
  fullWidth,
  type = "button",
  href,
  disabled,
  variant,
  onClick,
  active,
  icon,
  external,
}) => {
  const classes = classnames(mainClass, {
    [`${mainClass}--full-width`]: !!fullWidth,
    [`${mainClass}--${variant}`]: !!variant,
    [`${mainClass}--active`]: !!active,
  });

  return href ? (
    external ? (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={classes}
      >
        {children}
        {icon}
      </a>
    ) : (
      <Link to={href} className={classes}>
        {children}
        {icon}
      </Link>
    )
  ) : (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classes}
    >
      {children}
      {icon}
    </button>
  );
};

export default Button;
