/* eslint-disable */

import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import nav from "libs/nav";

import OldLogo from "images/old-logo-black.png";
import CondoLogo from "icons/condo-logo.svg";

const mainClass = "footer";

const Footer = () => {
  return (
    <footer className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__top`}>
          {/* <LogoIcon /> */}
          <img src={OldLogo} alt="" />
          <div className={`${mainClass}__top__columns`}>
            <div className={`${mainClass}__top__columns__col`}></div>
            <div className={`${mainClass}__top__columns__col`}>
              <div className={`${mainClass}__top__menu`}>
                {nav?.map(({ label, href }, index) => (
                  <Link key={index} to={href} activeClassName="current">
                    {label}
                  </Link>
                ))}
              </div>
            </div>
            <div className={`${mainClass}__top__columns__col`}></div>
          </div>
        </div>
        <div className={`${mainClass}__bottom`}>
          <small>
            2023 © Oak Property Sp. Z o. o. Wszelkie prawa zastrzeżone.
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/polityka-prywatnosci"
            >
              Polityce Prywatności
            </a>
            <br />
            Wizualizacje oraz informacje umieszczone na niniejszej stronie
            internetowej nie stanowią oferty w rozumieniu Kodeksu Cywilnego
          </small>
          <div className={`${mainClass}__bottom__right`}>
            <h4>Biuro sprzedaży</h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://condo.pl"
            >
              <CondoLogo />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
