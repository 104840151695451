import { object, bool, string } from "yup";

let contactFormSchema = object({
  email: string().required("Pole obowiązkowe").email("Niepoprawny email"),
  phone: string()
    .required("Pole obowiązkowe")
    .matches(/^\+?1?\d{9,15}$/, "Niepoprawny numer telefonu"),
  legal1: bool().oneOf([true], "Pole obowiązkowe").required("Pole obowiązkowe"),
  legal2: bool().oneOf([true], "Pole obowiązkowe").required("Pole obowiązkowe"),
  legal3: bool().oneOf([true], "Pole obowiązkowe").required("Pole obowiązkowe"),
});

export default contactFormSchema;
