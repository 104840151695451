import "./styles.scss";

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import nav from "libs/nav";

// import Logo from "icons/logo.svg";
import OldLogo from "images/old-logo-white.png";
import HamburgerIcon from "icons/hamburger.svg";
import PhoneIcon from "icons/phone.svg";
import MessageIcon from "icons/message_new.svg";
import ReservationIcon from "icons/reservation_new.svg";

const mainClass = "header";

const Header = ({
  location,
  setMobileMenuOpen,
  setContactModalOpen,
  activeHeader = false,
  phone,
}) => {
  const [is_scrolled, setIsScrolled] = useState(activeHeader);
  const [number_active, setNumberActive] = useState(false);

  useEffect(() => {
    if (!!activeHeader) return;

    if (window?.scrollY > 10) {
      setIsScrolled(true);
    }

    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 10);
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--is_scrolled`]: !!is_scrolled,
      })}
    >
      <div className="container">
        <Link to={!!location ? location : "/"}>
          {/* <Logo /> */}
          <img src={OldLogo} alt="" />
        </Link>
        <nav>
          {nav?.map(({ label, href }, index) => (
            <Link
              key={index}
              to={`${location}${href}`}
              activeClassName="current"
            >
              {label}
            </Link>
          ))}
        </nav>
        <HamburgerIcon
          className={`${mainClass}__hamburger`}
          onClick={() => setMobileMenuOpen(true)}
        />
        <div className={`${mainClass}__contact`}>
          <PhoneIcon />
          <div className={`${mainClass}__contact__phone`}>
            <small>Zadzwoń</small>
            {number_active ? (
              <a href={`tel:${phone}`}>{phone}</a>
            ) : (
              <span>+48 22 ...</span>
            )}
          </div>
          {!!!number_active && (
            <button onClick={() => setNumberActive(true)}>Pokaż numer</button>
          )}
          <div className={`${mainClass}__contact__btns`}>
            <button
              onClick={() => setContactModalOpen({ from: "header" })}
              className={`${mainClass}__contact__btn`}
            >
              <MessageIcon />
              <div className={`${mainClass}__contact__btn__text`}>
                <span>
                  Zapytaj o zakup
                  <br />
                  apartamentu
                </span>
              </div>
            </button>
            <a
              className={`${mainClass}__contact__btn`}
              href="https://www.seashell.pl?utm_source=www_sprzedaz&utm_medium=button+&utm_campaign=www_sprzedaz"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <ReservationIcon />
              <div className={`${mainClass}__contact__btn__text`}>
                <span>
                  Zarezerwuj
                  <br />
                  pobyt
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
