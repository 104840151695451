import "./styles.scss";

import React, { useState } from "react";

import FormContent from "components/Form";

import PhoneIcon from "icons/phone-form.svg";
import MailIcon from "icons/mail-form.svg";

const mainClass = "contact";

const Contact = ({ search, phone, email }) => {
  const [numberActive, setNumberActive] = useState(false);
  const [mailActive, setMailActive] = useState(false);

  return (
    <section id="kontakt-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h3>Kontakt</h3>
          <span>
            Kontakt posprzedażowy
            <br /> dla właścicieli apartamentów:
          </span>
          <small>
            Wszelkie zapytania dotyczące postępów prac, płatności itp. prosimy
            kierować na:
          </small>
          <div className={`${mainClass}__phone`}>
            <div className={`${mainClass}__phone--icon`}>
              <PhoneIcon />
            </div>
            <div className={`${mainClass}__phone--cta`}>
              <small>Zadzwoń</small>
              {numberActive ? (
                <a href={`tel:${phone}`}>{phone}</a>
              ) : (
                <span>+48 22 ...</span>
              )}
            </div>
            {!!!numberActive && (
              <button type="button" onClick={() => setNumberActive(true)}>
                Pokaż numer
              </button>
            )}
          </div>
          <div className={`${mainClass}__phone`}>
            <div className={`${mainClass}__phone--icon`}>
              <MailIcon />
            </div>
            <div className={`${mainClass}__phone--cta`}>
              <small>Napisz wiadomość</small>
              {mailActive ? (
                <a href={`mailto:${email}`}>{email}</a>
              ) : (
                <span>sea ...</span>
              )}
            </div>
            {!!!mailActive && (
              <button type="button" onClick={() => setMailActive(true)}>
                Pokaż adres
              </button>
            )}
          </div>
        </div>
        <FormContent search={search} variant="light" direction="vertical" />
      </div>
    </section>
  );
};

export default Contact;
