const nav = [
  {
    key: "o-inwestycji-section",
    label: "O inwestycji",
    href: "#o-inwestycji",
  },
  {
    key: "lokalizacja-section",
    label: "Lokalizacja",
    href: "#lokalizacja",
  },
  {
    key: "apartamenty-section",
    label: "Apartamenty",
    href: "#apartamenty",
  },
  {
    key: "galeria-section",
    label: "Galeria",
    href: "#galeria",
  },
  {
    key: "model-biznesowy-section",
    label: "Model biznesowy",
    href: "#model-biznesowy",
  },
  {
    key: "inwestor-section",
    label: "Inwestor",
    href: "#inwestor",
  },
];

export default nav;
