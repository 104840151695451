import "./styles.scss";

import React from "react";
import { useField } from "formik";
import classnames from "classnames";

const Input = ({
  name,
  placeholder,
  label,
  type = "text",
  multiline,
  ...rest
}) => {
  const [field, meta] = useField({ name, type });

  const is_error = meta.touched && !!meta.error;

  return multiline ? (
    <div className="input__wrapper">
      <textarea
        className={classnames("input multiline", {
          "input--error": !!is_error,
        })}
        name={name}
        placeholder={placeholder}
        {...field}
        {...rest}
      />
      {!!meta.error && <small>{meta.error}</small>}
    </div>
  ) : (
    <div className="input__wrapper">
      <input
        className={classnames("input", {
          "input--error": !!is_error,
        })}
        type={type}
        placeholder={placeholder}
        {...field}
        {...rest}
      />
      {!!meta.error && <small>{meta.error}</small>}
    </div>
  );
};

export default Input;
