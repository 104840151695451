import "./styles.scss";
import { Link } from "gatsby";

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const mainClass = "popup-cookie";

const InitPopup = ({ image, link }) => {
  const [{ popup_accepted }, setCookie] = useCookies(["popup_accepted"]);
  const [show_cookie, setShowCookie] = useState(false);

  useEffect(() => {
    setShowCookie(!popup_accepted);
  }, [popup_accepted]);

  if (!!!show_cookie) return null;

  return (
    <div className={mainClass}>
      {!!link ? (
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={`${mainClass}__image`}
        >
          <img src={image} alt="" />
          <button
            onClick={(e) => {
              e.preventDefault();
              setCookie("popup_accepted", "true", { path: "/" });
            }}
          />
        </Link>
      ) : (
        <div className={`${mainClass}__image`}>
          <img src={image} alt="" />
          <button
            onClick={() => setCookie("popup_accepted", "true", { path: "/" })}
          />
        </div>
      )}
    </div>
  );
};

export default InitPopup;
