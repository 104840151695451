import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

function Seo({ title, description, lang, meta }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          name: `og:image`,
          content: withPrefix(`/og-image.jpg`),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      <meta name="description" content={description} />
      <meta
        name="facebook-domain-verification"
        content="4v6sl3sn0vco3xonrfo0el4fr4zyli"
      />
      <meta
        name="google-site-verification"
        content="1xQ533TrnP-U0RKa8vsW7OLKqJE0JSlPtco0sPmc6EI"
      />
      <meta
        name="google-site-verification"
        content="aZdA3B_ZYqpP4Eb7y5YAM9jorgkgLrpJiKJLVeEAUOk"
      />
      <meta
        name="keywords"
        content="Apartamenty na sprzedaż w Łukęcinie, Luksusowe apartamenty nad Bałtykiem na sprzedaż, Luksusowe apartamenty inwestycyjne w otulinie lasu nad morzem!, Apartamenty inwestycyjne w nad morzem, Zainwestuj w luksusowy apartament nad morzem, Zainwestuj w apartament 2 kroki od Bałtyku, Zainwestuj w apartamenty 90 metrów od morza, Zainwestuj we własny apartament nad morzem, Zainwestuj w apartament nad morzem i zarabiaj!, Kup apartament w luksusowym obiekcie nad Morzem Bałtyckim, Kup luksusowy apartament nad morzem, Kup apartament nad morzem w otulinie sosnowego lasu, Kup apartament nad morzem i zarabiaj na wynajmie!, Apartamenty condo na sprzedaż w Łukęcinie koło Pobierowa, Zainwestuj w apartament condo nad morzem, zarabiaj i wypoczywaj, Kup apartament condo nad morzem i zarabiaj na wynajmie, Inwestuj i zarabiaj – apartamenty na sprzedaż w Łukęcinie koło Pobierowa, Unikatowa, luksusowa inwestycja w samym sercu lasu nad morzem, Twój drugi dom nad morzem, 90 metrów od plaży., Sea Shell Apartments to apartamenty na sprzedaż Łukęcinie k. Pobierowa, Zainwestuj we własny apartament w Sea Shell Apartments – wypoczywaj i zarabiaj., Zainwestuj w condo nad polskim morzem. Sprawdź Sea Shell nad Bałtykiem., Apartamenty w otulinie sosnowego lasu 90 metrów od morza – sprawdź Sea Shell., Apartamenty condo w Sea Shell Apartments – zarabiaj na wynajmie."
      />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
