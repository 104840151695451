import React, { useState } from "react";
import { CookiesProvider } from "react-cookie";

import {
  Seo,
  Header,
  Footer,
  Contact,
  // CookiesPopup,
  MobileMenu,
  InitPopup,
  // FloatButton,
} from "./components";

function Layout({
  children,
  seo = {},
  search,
  apartments,
  setContactModalOpen,
  activeHeader,
  phone,
  phone2,
  email,
  popup,
  popupLink,
}) {
  const [mobile_menu_open, setMobileMenuOpen] = useState(false);

  return (
    <CookiesProvider>
      <Seo {...seo} />
      <Header
        setMobileMenuOpen={setMobileMenuOpen}
        setContactModalOpen={setContactModalOpen}
        activeHeader={activeHeader}
        location={search}
        phone={phone}
      />
      {/* <CookiesPopup /> */}
      <main>{children}</main>
      <Contact
        phone={phone2}
        email={email}
        search={search}
        apartments={apartments}
      />
      <Footer />
      <MobileMenu
        is_active={mobile_menu_open}
        onExit={() => setMobileMenuOpen(false)}
      />
      {!!popup && <InitPopup image={popup} link={popupLink} />}
      {/* <FloatButton /> */}
    </CookiesProvider>
  );
}

export default Layout;
